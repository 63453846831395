/*
@font-face {
font-family: "Akrobat Regular";
src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Regular.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Regular.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Regular.ttf) format("truetype")
}

@font-face {
font-family: "Akrobat Bold";
src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Bold.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Bold.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Bold.ttf) format("truetype")
}

@font-face {
font-family: "Akrobat Light";
src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Light.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Light.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Light.ttf) format("truetype")
}

@font-face {
font-family: "Akrobat Black";
src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Black.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Black.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Black.ttf) format("truetype")
}

@font-face {
font-family:"myriad-pro";
src:url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family: 'myriad-pro-bold';
src: url('../public/fonts/myriad_pro_semibold.otf') format('opentype');
}

@font-face {
font-family: 'Hyperspace Race Italic';
src:
url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.eot?') format('eot'),
url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.woff') format('woff'),
url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.ttf') format('truetype');
font-weight: normal;
font-style: normal;
font-display: swap;
}
*/

@import url("https://use.typekit.net/gfl0qkn.css");

@font-face { 
	font-family: 'Hyperspace Race Italic';  
	src: 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.eot?') format('eot'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.woff') format('woff'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'Akrobat';  
	src: 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/MiConv.com__Akrobat-Regular.eot?') format('eot'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/MiConv.com__Akrobat-Regular.woff') format('woff'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/Akrobat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'Akrobat Bold';  
	src: 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/MiConv.com__Akrobat-Bold.eot?') format('eot'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/MiConv.com__Akrobat-Bold.woff') format('woff'), 
		url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Akrobat/Akrobat-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



:root {
--gris: #3c3c3b;
--amarillo: #FFD400;
--naranja: #EA5B0C;
--rojo: #c21632;
--verde: #A6CE39;
--claro: #f8f9fa;
--grisClaro: #efefef;
--grisMedio: #9b9b9b;
}

body {
  margin: 0;
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cont-body {
  min-height: 50svh;
}


a {
  text-decoration: none;
  font-family: "myriad-pro", sans-serif;
}

button {
  cursor: pointer;
  font-family: "myriad-pro", sans-serif;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
margin-top: 0;
margin-bottom: .5rem;
font-weight: 500;
line-height: 1.2;
}


@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/

/***** Header *****/

/* Estilos básicos del header */
.header {
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 20;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  max-width: 140ch;
  margin: auto;
  max-width: 90svw;
}

.cont-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.hs-menu-wrapper ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.hs-menu-wrapper ul li a:hover,
.hs-menu-wrapper ul li.active-branch a {
  color: var(--verde);
}
.hs-menu-wrapper ul li a {
  color: #000;
  font-size: 14px;
  padding: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: color .3s ease;
  font-family: myriad-pro, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.header-iconos ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-iconos ul li {
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}
.header-iconos ul li a {
  text-decoration: none;
  color: #333;
}

#carrito-cant {
  background-color: var(--verde);
  color: white;
  padding: 3px;
  font-size: 12px;
  position: absolute;
  top: -1rem;
  right: -1.2rem;
  border: 2px solid #fff;
  width: 1rem;
  height: 1rem;
  display: grid;
  place-content: center;
  border-radius: 2rem;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin: 1rem 0 1rem 1rem;
  color: var(--naranja);
}
.menu-toggle .fa-bars {
  color: var(--verde)
}

nav.header-iconos ul li a svg, nav.header-iconos ul li svg {
  color: var(--verde);
  transition: color .3s ease;
}
nav.header-iconos ul li a:hover svg,
nav.header-iconos ul li.logout:hover svg {
  color: var(--naranja);
}


/***************************/
/***************************/
/***************************/
/***************************/
/***************************/
/***************************/
/***************************/
/***************************/
/***************************/

/********* Cuerpo *********/


/***************************/
/***************************/



/***** Catalog *****/


.spacing_txt {
font-size: 12px;
letter-spacing: 4px;
}



/***** Carrousel Logo *****/

.carousel-container {
width: 100%;
overflow: hidden;
background-color: rgba(182, 189, 193, 0.5);
padding-top: 36px;
padding-bottom: 36px;
}

.carousel {
display: flex;
transition: transform 0.5s ease;
/*user-select: none;*/
justify-content: flex-start;
}

.logo {
flex: 0 0 200px;
margin-right: 10px;
display: flex;
align-items: center;
}

.info-form .logo{
  width: 100%;
  max-width: 300px;
  object-fit: contain;
  object-position: center;
}

.logo img {
max-width: 100%;
}

/* Breakpoint para tablet */
@media (max-width: 768px) {
.carousel {
width: auto !important;
}

.logo {
flex: 0 0 calc(33.33% - 10px);
}
}

/* Breakpoint para móvil */
@media (max-width: 480px) {
.logo {
flex: 0 0 calc(50% - 10px);
}
}



/***** Footer *****/

footer {
background-color: #000;
padding: 4rem 4rem 1rem 4rem;
color: #fff;
font-size: 14px;
}

footer>div {
display: flex;
flex-wrap: wrap;
gap: 1rem;
align-items: flex-start;
justify-content: center;
padding-bottom: 3rem;
}

footer .span3 {
flex: 1 0 200px;
}

footer .flex_container .span3 ul {
  display: flex;
  flex-direction: column;
}

span#hs_cos_wrapper_left_menu ul,
span#hs_cos_wrapper_right_menu ul,
span#hs_cos_wrapper_contact_info ul {
list-style: none;
padding: 0;
}

span#hs_cos_wrapper_left_menu ul li a,
span#hs_cos_wrapper_right_menu ul li a {
color: #fff;
text-decoration: none;
}

div#hs_cos_wrapper_copy {
font-size: 12px;
letter-spacing: 1px;
}

footer .flex_container h3 {
font-size: 1.5rem;
}

footer li {
margin: 10px 0;
}

footer .lightgreen{
color: var(--verde);
}


/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/


@keyframes tilt-shaking {
0% { transform: rotate(0deg); }
25% { transform: rotate(15deg); }
50% { transform: rotate(0eg); }
75% { transform: rotate(-15deg); }
100% { transform: rotate(0deg); }
}

@keyframes vertical-shaking {
0% { transform: translateY(0) }
25% { transform: translateY(10px) }
50% { transform: translateY(-10px) }
100% { transform: translateY(0) }
}

#user-menu {
position: absolute;
top: 90px;
background-color: white;
color: black;
cursor: auto;
box-shadow: 0 0 5px black;
width: 150px;
transition: transform .2s;
}

#user-menu.oculto {
transform: translateY(-50%) scaleY(0);
overflow: hidden;
}

#user-menu ul {
padding: 10px;
margin: none;
}

#user-menu li {
cursor: pointer;
list-style: none;
margin-top: 10px;
}

h3.cat-titulo {
  margin: 2rem auto;
  width: 80svw;
  max-width: 1200px;
}
.filter_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  width: 80svw;
  max-width: 1200px;
  gap: 2rem;
}
.cont-galeria .cat-titulo {
  color: var(--gris);
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.cont-galeria .sub-familias {
width: fit-content;
flex-wrap: wrap;
display: flex;
margin: auto;
}

.cont-galeria .sub-familias button {
  cursor: pointer;
  text-align: center;
  margin: 0.5rem 1rem;
  border: solid 1px white;
  border-radius: .25rem;
  line-height: 1;
  padding: 0.6rem 1rem;
  display: grid;
  place-content: center;
  color: white;
  background-color: var(--verde);
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color .3s ease;
}
.cont-galeria .sub-familias button:hover,
.cont-galeria .sub-familias button.active {
  background-color: var(--naranja);
}

.select-filter {
  margin: auto;
  max-width: 1200px;
  width: 80svw;
}
.select-filter select {
  padding: .36rem .72rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 11px;
  border-radius: .25rem;
}
.select-filter select option {
  font-size: 11px;
}

@media (max-width: 768px) {
  .cont-galeria .sub-familias {
    display: none;
  }
  .sub-familias {
    display: none;
  }
}

@media (min-width: 769px) {
  .sub-familias {
    display: block;
  }
}

.pages-galeria {
  width: 140ch;
  margin: auto;
  max-width: 90svw;
}
.cont-galeria .pagination {
  display: flex;
  list-style: none;
  margin: 2rem auto;
  width: fit-content;
  padding: 0;
}

.cont-galeria .pagination .page-item {
  margin: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  border-radius: 2rem;
  transition: all .3s ease;
  height: 32px;
  width: 32px;
  place-content: center;
  display: grid;
}
.cont-galeria .page-item.active {
  background-color: var(--verde);
  color: #fff;
}
.cont-galeria .page-item:hover {
  background-color: var(--naranja);
}
.galerias {
  display: grid;
  margin-top: 2rem;
  min-height: 380px;
}

.galerias #MOTOS {
  order: 1;
}

.galerias #ACCESORIOS {
  order: 2;
}

.galeria {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 2rem;
  margin: 2rem auto;
  width: 80svw;
  max-width: 1200px;
}

.galeria .item_product {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  overflow: hidden;
  transition: transform 0.2s;
  display: grid;
  justify-content: normal;
  align-items: stretch;
  padding: 1rem;
  width: calc(100% - 2rem - 2px);
  border-radius: .25rem;
}

.galeria .image {
  mix-blend-mode: multiply;
  max-height: 100%;
  margin: auto;
}
.galeria .image.notImage {
  max-height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  height: auto;
}

.galeria .item_product h4.tipo {
  font-size: 14px;
  color: var(--verde);
  margin-block: 1rem;
}
.galeria .item_product button {
  cursor: pointer;
  text-align: center;
  margin: auto 1rem 1rem 1rem;
  margin-inline: 0;
  border: solid 1px white;
  border-radius: .25rem;
  line-height: 1;
  height: 48px;
  padding: 0.8rem 2rem;
  display: grid;
  place-content: center;
  color: white;
  background-color: var(--verde);
  font-size: 14px;
  transition: background-color .3s ease;
  z-index: 10;
}
.galeria .item_product button:hover {
  background-color: var(--naranja);
}
.galeria .item_product .agotado{
  text-align: center;
  margin-inline: 0;
  border: solid 1px transparent;
  line-height: 1;
  height: 48px;
  min-width: calc(150px - 1.6rem);
  padding: 0.8rem 2rem;
  display: grid;
  place-content: center;
  color: var(--rojo);
  font-size: 14px;
  margin-top: auto;
}
.item_product p.precio.cantidad {
  display: flex;
  justify-content: space-between;
}

.cont-detalle {
  margin: 2rem auto;
}

.flex-column {
  display: flex;
  max-width: 80vw;
  margin: 2rem auto;
  gap: 4rem;
  width: 140ch;
  min-height: 40svh;
}

@media (max-width: 768px) {
  .flex-column {
    flex-direction: column;
  }
  .cont-branding {
    flex-wrap: wrap;
  }
}

img.image {
  width: 420px;
  max-width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  border-radius: 0.25rem;
}
img.image.notImage {
  background-color: #efefef;
}

.cont-detalle .con-info {
  width: 420px;
  max-width: 80vw;
}

.cont-detalle .con-info .gris:not(a){
color: darkgrey;
font-family: sans-serif;
}



ul.talles {
  flex-wrap: wrap;  
}
ul.productColors, ul.talles {
  padding: 0;
  list-style: none;
  display: flex;
  gap: .25rem;
}
ul.productColors button {
  background-color: transparent;
  border: 1px solid transparent;
  appearance: none;
  border-radius: 34px;
  padding: 0;
  margin: 0;
  outline: none;
}
button.talla-button.active,
button.color-button.active {
  border-color: var(--grisMedio);
  box-shadow: 0px 2px 5px -2px var(--grisMedio);
}
ul.productColors button span {
  position: relative;
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  border-radius: 32px;
}
ul.productColors span:before, ul.productColors span:after {
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
ul.productColors span:before {
  left: 0;
  border-radius: 50% 0 0 50%;
}
ul.productColors span:after {
  left: 50%;
  border-radius: 0 50% 50% 0;
}
ul.productColors button[data-color1=VERDE] span:before {
  background-color: var(--verde);
}
ul.productColors button[data-color2=VERDE] span:after {
  background-color: var(--verde);
}
ul.productColors button[data-color1=NEGRO] span:before {
  background-color: #000;
}
ul.productColors button[data-color2=NEGRO] span:after {
  background-color: #000;
}
ul.productColors button[data-color1=ROJO] span:before {
  background-color: var(--rojo);
}
ul.productColors button[data-color2=ROJO] span:after {
  background-color: var(--rojo);
}
ul.productColors button[data-color1=BLANCO] span:before {
  background-color: #fff;
}
ul.productColors button[data-color2=BLANCO] span:after {
  background-color: #fff;
}
ul.productColors button[data-color1=PLATEADO] span:before {
  background-color: #e7e7e7;;
}
ul.productColors button[data-color2=PLATEADO] span:after {
  background-color: #e7e7e7;;
}
ul.productColors button[data-color1=AZUL] span:before {
  background-color: #262ec9;;
}
ul.productColors button[data-color2=AZUL] span:after {
  background-color: #262ec9;;
}
ul.productColors button[data-color1=CELESTE] span:before {
  background-color: #6baddc;;
}
ul.productColors button[data-color2=CELESTE] span:after {
  background-color: #6baddc;;
}
ul.productColors button[data-color1=MORADO] span:before {
  background-color: #402b61;;
}
ul.productColors button[data-color2=MORADO] span:after {
  background-color: #402b61;;
}
ul.productColors button[data-color1=ANARANJADO] span:before {
  background-color: #ffa11d;;
}
ul.productColors button[data-color2=ANARANJADO] span:after {
  background-color: #ffa11d;;
}
ul.productColors button[data-color1=CAFE] span:before {
  background-color: #5e1c14;;
}
ul.productColors button[data-color2=CAFE] span:after {
  background-color: #5e1c14;;
}
ul.productColors button[data-color1=AMARILLO] span:before {
  background-color: #f1c40f;;
}
ul.productColors button[data-color2=AMARILLO] span:after {
  background-color: #f1c40f;;
}

.productSizes {
  gap: 1rem;
  display: inline-flex;
  margin-bottom: 1rem;
  margin-inline: 0;
  padding: 0;
  list-style: none;
}
ul.productSizes li button {
  background-color: var(--claro);
  border: 1px solid var(--claro);
  color: var(--gris);
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 38px;
  height: 38px;
  text-align: center;
  border-radius: 38px;
  line-height: 1;
}


.btn_container.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gris);
}


.cont-detalle .con-info .nombre {
font-size: 32px;
font-weight: bold;
margin-bottom: 0px;
margin-top: 0px;

}

.cont-detalle .price {
font-size: 20px;
font-weight: bold;
margin-top: 5px;

}

.cont-detalle .con-info .otras-caracteristicas {
color: darkgrey;
}

.productDetails .btn_container button {
  cursor: pointer;
  text-align: center;
  margin-left: 0;
  border: solid 1px var(--verde);
  border-radius: .25rem;
  line-height: 1;
  min-height: calc(40px - 1rem);
  min-width: calc(150px - 1.6rem);
  padding: 0.8rem 2rem;
  display: grid;
  place-content: center;
  color: white;
  background-color: var(--verde);
  transition: background-color .3s ease;
  font-size: 14px;
}
.productDetails .btn_container button:hover {
  background-color: var(--naranja);
  border-color: var(--naranja);
}
.productDetails .btn_container button.talla.active {
  background-color: var(--grisClaro);
  color: var(--gris);
}

.cont-branding {
  display: flex;
  margin: auto;
  width: 700px;
  max-width: 80vw;
  gap: 1rem;
}

.cont-branding img {
  margin-top: auto;
  margin-bottom: auto;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.cont-form {
margin-bottom: 40px;
background-color: var(--claro);
padding-top: 40px;
padding-bottom: 40px;
margin-bottom: 0;
}

.cont-form > h3,
.cont-form > p {
color: var(--gris);
max-width: 1200px;
margin-left: auto;
margin-right: auto;
text-align: center;
}
.cont-form > h3 {
font-size: 30px;
font-weight: 700;
}

form {
max-width: 600px;
margin: auto;
}


form .cont-button {
display: flex;
}

form button {
padding: 5px 15px;
border: solid 1px white;
color: white;
margin-right: 2rem;
margin-top: 2rem;
background-color: var(--verde);
font-size: 26px;
transition: background-color .3s ease, color .3s ease;
}

form button.enabled {
background-color: var(--verde);
}
form button.enabled:hover {
  background-color: var(--naranja);
}
form button.disabled {
background-color: var(--gris);
cursor: not-allowed
}

.lista-errores-form{
display: flex;
}

.lista-errores-form ul{
margin: auto;
list-style: none;
}

.lista-errores-form ul li {
color: var(--rojo);
font-weight: bold;
font-family: "Akrobat Bold";
}

.cart-container {
  max-width: 90svw;
  margin: 2rem auto;
  width: 140ch;
  min-height: 40svh;
}

.cont-sticky {
background-color: #fff;
padding: 2rem 0; 
}

.cont-carrito {
  width: 100%;
  max-width: 90svw;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2rem auto;
  gap: 2rem;
  position: relative;
  align-items: flex-start;
}
.cont-carrito-details {
  display: flex;
}
.cont-carrito > h3 {
font-size: 30px;
font-weight: 700;
color: var(--gris);
max-width: 1200px;
margin-left: auto;
margin-right: auto;
text-align: center;
margin-block: 2rem;
}

.cont-carrito .total{
width: fit-content;
margin-left: auto;
font-weight: bold;
font-family: "Akrobat Black";
font-size: 25px;
margin-right: 100px;
}


@media (max-width: 768px) {
  .cont-carrito {
    flex-direction: column-reverse;
  }
  .cont-carrito .checkout_container {
    position: relative;
    width: 100%;
  }
  .cont-carrito .checkout_container .resumen-carrito {
    max-width: calc(100% - 20px);
    width: 100%;
  }
  .cont-carrito .cart_products {
    width: 100%;
  }
}

.no-compras {
text-align: center;
font-size: 20px;
margin-bottom: 40px;
margin-top: 40px;
}

.productoCarrito .image {
height: auto;
width: 80px;
background-size: cover;
background-position: center;
}

.productoCarrito .cont-info {
display: flex;
align-items: center;
}

.productos-select .cont-info .texto {
display: flex;
align-items: center;
width: 100%;
font-family: "Akrobat Black";
}


.productos-select .cont-info .texto .nombre{
width: inherit;
}


.productos-select .cont-info .cantidad {
display: flex;
}

.productos-select .cont-info .cantidad span {
margin-right: 5px;
}

.productos-select .cont-info .precio {
display: flex;
margin-left: 5px;
}


.productos-select .cont-info .texto .separador {
height: 1px;
background: black;
margin-left: 20px;
margin-right: 20px;
width: inherit;
}

.productos-select .cont-info .cerrar {
font-size: 25px;
font-weight: bold;
margin-left: 15px;
cursor: pointer;
color: red;
}

.productos-select .cont-info .cant-buttons {
display: flex;
padding: 5px;
}

.productos-select .cont-info .cant-buttons div {
padding: 5px;
color: white;
width: 20px;
height: 20px;
display: flex;
justify-content: center;
cursor: pointer;
}

.productos-select .cont-info .cant-buttons div:first-child {
background-color: var(--rojo);
}

.productos-select .cont-info .cant-buttons div:last-child {
background-color: var(--verde);
margin-left: 5px;
}

form fieldset {
border: none;
max-width: 300px;
margin: auto;
display: flex;
padding: 0;
}
fieldset.cont-politicas, fieldset.cont-terminos {
margin-top: 2rem;
display: block;
}
fieldset.cont-politicas p, fieldset.cont-terminos p {
  color: var(--gris);
  font-size: 12px;
  margin-block: 0;
}
fieldset.cont-politicas a, fieldset.cont-terminos a {
  color: var(--verde);
}
fieldset.cont-politicas > div, fieldset.cont-terminos > div {
  width: 100%;
}

.info-form {
  display: flex;
  width: fit-content;
  margin: auto;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 3rem;
  max-width: 80svw;
  gap: 2rem;
}
.info-form > div {
  flex: 1 0 240px;
}

/* Formularios */


form {
margin: 4rem auto;
}

form fieldset {
max-width: 640px;
gap: .5rem;
}

form fieldset input {
  margin-right: 0;
  accent-color: var(--verde);
  color: var(--gris)
}
form fieldset input[type="checkbox" i] {
  margin-right: .25rem;
}

form fieldset>div {
  margin: 0 auto;
  width: 50%;
}
form fieldset.fieldset_one > div {
  width: 100%;
}

form fieldset select, form fieldset input:not([type=checkbox]), form fieldset textarea {
  margin-block: 1rem;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  padding-left: 1rem;
  width: calc(100% - 1rem);
}

form fieldset select {
  width: 100%;
  color: var(--gris);
  padding-inline: 4px;
  min-height: 50px;
}


form button {
background-color: darkgray;
border: none;
padding: 10px 22px;
font-size: 18px;
color: #fff;
cursor: pointer;
border-radius: .25rem;
}

form input:not(.checkbox),
form select {
padding: 0;
width: calc(100% - 2px);
outline: none;
line-height: 18px;
border-radius: .25rem;
border: 1px solid var(--gris);
min-height: 48px;
}

form label {
margin: 1rem;
display: inline-block;
font-size: 15px;
color: var(--gris);
display: none;
}

form ul {
list-style: none;
padding: 0;
margin: 0;
}

form textarea input {
width: calc(100% - 0) !important;
min-height: 140px;
}

form fieldset .aceptar-politicas, form fieldset .aceptar-terminos {
display: flex;
}
form fieldset .aceptar-politicas label, form fieldset .aceptar-terminos label {
margin: 0;
}
form p.error {
color: var(--rojo);
float: inline-start;
font-size: 12px;
margin: .25rem 0;
}


/*****************************/

@media (min-width: 900px) {
  .galeria .producto:hover .image {
  background-color: var(--verde);
  }
}

.cont-pago-popup .button-pago {
padding: 10px 20px;
background-color: var(--naranja);
color: white;
/* width: -moz-fit-content;*/
/*width: fit-content;*/
border-radius: 15px;
text-transform: uppercase;
margin: auto;
margin-top: 40px;
margin-bottom: 40px;
font-size: 30px;
width: fit-content;
}

.cont-pago-popup .button-pago a {
text-decoration: none;
color: white;
}

.mis-compras {
  margin-top: 3rem;
  min-height: 90vh;
  margin-bottom: 3rem;
}
.mis-compras .user {
  background-color: var(--claro);
  width: 280px;
  padding: 1rem 2rem 1.5rem;
  border-radius: .25rem;
}
.mis-compras .user h3 {
  margin-top: 1rem;
}
.mis-compras .lista-compras {
  max-width: 90svw;
  width: 100%;
  display: grid;
  gap: 1rem;
}
.account_container {
  display: flex;
  align-items: flex-start;
  max-width: 90svw;
  width: 140ch;
  margin: auto;
  justify-content: flex-start;
  gap: 2rem;
}

.mis-compras .lista-compras .titulo {
text-align: center;
font-size: 22px;
font-weight: bold;
}

.mis-compras .lista-compras .compra {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: .25rem;
  font-size: 14px;
  color: var(--gris);
}
.mis-compras .lista-compras .compra:nth-child(odd) {
  background-color: var(--claro);
}
.compra p span {
  padding: .25rem;
  border-radius: .25rem;
  color: #fff;
  font-size: 12px;
  margin-left: .5rem;
}
.compra .REJECTED p span {
  background-color: var(--rojo);
}
.compra .APPROVED p span {
  background-color: var(--verde);
}
.compra .PENDING p span {
  background-color: var(--naranja);
}
.compra .ERROR p span {
  background-color: var(--rojo);
}

.pago-pendiente a {
  background-color: var(--naranja);
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin: 0.5rem 0;
  padding: 5px 15px;
  border: solid 1px white;
  line-height: 1;
  min-height: calc(40px - 1rem);
  padding: .5rem .8rem;
  display: grid;
  place-content: center;
  border-radius: .25rem;
}

.mis-compras .lista-compras form {
  max-width: 90svw;
  width: 340px;
}

.mis-compras .lista-compras form fieldset {
margin: 0;
max-width: 340px;
}
.mis-compras .lista-compras form fieldset.field {
  width: 100%;
  display: block;
}
.mis-compras .lista-compras form input {
min-width: 100%;
}

.mis-compras .lista-compras form .action {
  display: inline-block;
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 0;
}
.mis-compras .lista-compras form button {
  margin: 0;
}

.error-formulario {
background-color: red;
border-radius: 20px;
height: 40px;
color: white;
max-width: 300px;
font-family: 'Akrobat Bold';
text-align: center;
font-size: 18px;
text-transform: uppercase;
display: flex;
margin: auto;
}

.error-formulario p {
margin: auto;
}

/******************/


.cart_products > div.cart_products_list {
  position: sticky;
  top: calc(86.34px + 1rem);
  background-color: #fff;
  z-index: 1;
}
.producto-carrito {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: .25rem;
  padding: 10px;
  font-size: 14px;
  background-color: var(--claro);
  margin-bottom: .24rem;
}
.producto-carrito[data-items-min=true] .producto-info {
  justify-content: flex-start;
}
.producto-carrito[data-items-min=true] .producto-info > div {
  width: 100%;
  text-align: left;
}
.producto-carrito[data-items-min=true] .producto-info .itemPrice {
  margin-left: auto;
}
.producto-carrito .producto-info table, .producto-carrito .producto-info tr {
  width: 100%;
}
.producto-carrito .producto-info .descripcion {
  text-align: left;
}
.producto-carrito .producto-info .colorTalla {
  text-align: right;
}
.producto-carrito .producto-info .itemPrice {
  text-align: right;
  width: 65px;
}
.producto-carrito[data-items-min=true] .cant-buttons button {
  padding: 0 .6rem;
  margin: 0 .6rem;
}

.cart_detail_container.empty .cart_products {
  width: 100%;
}

.cart_detail_container.empty .cart_products_list {
  text-align: center;
  position: relative;
  top: unset;
}

.producto-info {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
  /*flex-wrap: wrap;*/
}
.producto-info img {
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
}
.producto-info > div {
  display: inline-flex;
  gap: 2rem;
  margin-right: 2rem;
  margin-left: auto;
  align-items: center;
}
.producto-info p {
  margin-block: 0;
  font-size: 13px;
}
@media (max-width: 900px) {

  .producto-info {
    gap: 1rem;
  }
  .producto-info img, .producto-carrito .producto-info .colorTalla {
    display: none;
  }
}

.cant-buttons {
  display: flex;
  align-items: center;
}

.btn-decrease, .btn-increase, .btn-clear {
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  opacity: .5;
  transition: opacity .3s ease;
  min-width: 38px;
  min-height: 38px;
}
.btn-decrease:hover, .btn-increase:hover, .btn-clear:hover {
  opacity: 1;
}
.btn-clear {
  color: var(--rojo);
}

.cart_products {
  width: calc(100% - 300px - 2rem);
}
.cart_products .producto-carrito span.no_controls {
  min-width: 60px;
  display: grid;
  place-content: center;;
}

.checkout_container {
  width: auto;
  min-width: 300px;
  position: sticky;
  top: calc(86.34px + 1rem);
  height: auto;
}
.resumen-carrito {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: .25rem;
  padding: 10px;
  max-width: 260px;
  color: var(--gris);
}
.envio-detalles {
  width: 100%;
  margin-block: .5rem;
}
.resumen-carrito fieldset {
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;
}
.resumen-carrito fieldset div, .resumen-carrito fieldset div label {
  display: flex;
  justify-content: space-between;
}
.resumen-carrito fieldset div {
  position: relative;
  margin-bottom: .5rem;
}
.resumen-carrito fieldset div label {
  width: 100%;
  font-weight: bold;
  font-size: 13px;
}
.resumen-carrito fieldset div label span {
  text-align: right;
  font-weight: normal;
}
.resumen-carrito fieldset div select {
  border: none;
  text-align: right;
  position: relative;
  font-size: 12px;
}
.resumen-carrito fieldset div:has(.alert)::after {
  content: "Selecciona una ciudad para el envío.";
  color: var(--rojo);
  float: inline-end;
  font-size: 12px;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
}

.resumen-carrito p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  margin: .5rem;
  font-weight: bold;
}

.final_checkout {
  border: 1px solid #ddd;
  padding: 1rem 10px;
  background-color: var(--claro);
  border-radius: .25rem;
  margin-top: 1rem;
}
.final_checkout button {
  background-color: var(--naranja);
  color: #fff;
  width: 100%;
  cursor: pointer;
  text-align: center;
  margin: 0.5rem 0 0 0;
  padding: 5px 15px;
  border: solid 1px var(--naranja);
  line-height: 1;
  min-height: calc(40px - 1rem);
  padding: .5rem .8rem;
  display: grid;
  place-content: center;
  border-radius: .25rem;
}

p.cart_total {
  font-size: 1.5em;
  color: var(--verde);
  font-family: "Akrobat Bold";
  margin-block: .25rem;
}

.additional-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  gap: 1rem;
}
.btn-clear-cart, .btn-continue-shopping {
  border-radius: .25rem;
}
.btn-clear-cart {
  cursor: pointer;
  text-align: center;
  border: solid 1px transparent;
  line-height: 1;
  padding: 0.8rem 0;
  width: 100%;
  display: grid;
  place-content: center;
  color: var(--gris);
  font-size: 11px;
  background-color: var(--claro);
  transition: background-color .3s ease, color .3s ease;
}
.btn-clear-cart:hover {
  background-color: var(--rojo);
  color: #fff;
}
.btn-continue-shopping {
  cursor: pointer;
  text-align: center;
  border: solid 1px var(--verde);
  line-height: 1;
  padding: 0.8rem 1rem;
  display: grid;
  place-content: center;
  font-size: 11px;
  background-color: transparent;
  color: var(--verde);
  width: 100%;
  transition: background-color .3s ease, color .3s ease;
}
.btn-continue-shopping:hover {
  background-color: var(--verde);
  color: #fff;
}

/*******************************/
/*******************************/
/*******************************/
/*******************************/
/*******************************/
/*******************************/

/* Botones */

a.btn {
  border: solid 1px var(--verde);
  cursor: pointer;
  text-align: center;
  margin-left: 0;
  border: solid 1px white;
  border-radius: .25rem;
  line-height: 1;
  min-height: calc(40px - 1rem);
  min-width: calc(150px - 1.6rem);
  padding: 0.8rem 2rem;
  display: grid;
  place-content: center;
  color: white;
  transition: background-color .3s ease;
  font-size: 14px;
  box-sizing: border-box;
}
a.btn.inline {
  display: inline-block;
}
a.btn.naranja {
  border: solid 1px var(--naranja);
  background-color: var(--naranja);
}
a.btn.naranja:hover {
  border: solid 1px var(--verde);
  background-color: var(--verde);
}

a.btn.verde, button.btn.verde {
  border: solid 1px var(--verde);
  background-color: var(--verde);
}
a.btn.gris, button.btn.gris {
  border: solid 1px darkgrey;
  background-color: darkgrey;
  color: #fff;
}
a.btn.gris:hover, button.btn.gris:hover {
  border: solid 1px var(--naranja);
  background-color: var(--naranja);
}
.btn_container.flex a.btn {
  color: darkgrey;
  border-color: darkgrey;
}
.btn_container.flex a.btn:hover {
  border-color: var(--naranja);
}

.btn.agotado {
  cursor: pointer;
  text-align: center;
  margin-left: 0;
  border-radius: .25rem;
  line-height: 1;
  min-height: calc(40px - 1rem);
  min-width: calc(150px - 1.6rem);
  padding: 0.8rem 2rem;
  display: grid;
  place-content: center;
  color: white;
  transition: background-color .3s ease;
  font-size: 14px;
  box-sizing: border-box;
}
.btn_container.flex .btn.agotado {
  color: var(--rojo);
  background-color: #fff;
}

.alert-expiration-time {
  padding: .5rem;
  background-color: var(--claro);
  color: var(--gris);
  border-radius: .25rem;
  font-size: 12px;
  margin-top: 1rem;
}
.alert-expiration-time p {
  margin: 0;
}

.alerta-pendiente {
  padding: .5rem;
  background-color: var(--naranja);
  color: #fff;
  border-radius: .25rem;
  font-size: 12px;
}
.cart_products {
  width: calc(100% - 300px - 2rem);
}
.alerta-pendiente p {
  margin: 0;
}

a.btn.fichaTecnica:hover {
  background-color: var(--naranja);
  color: #fff;
}



/* Estilos responsivos */
@media (max-width: 900px) {
  .hs-menu-wrapper ul li a {
    padding: .6rem .6rem;
    display: grid;
  }
  .header_container {
    flex-direction: column-reverse;
  }
  nav.header-iconos {
    margin-left: auto;
    margin-top: 1rem;
  }
  .cart_products > div.cart_products_list, .checkout_container {
    top: calc(86.34px + 3rem);
  }
}
@media (max-width: 768px) {
  .header_container {
    flex-direction: row;
  }
  nav.header-iconos {
    margin-top: 0;
  }
  .header_container .hs-menu-wrapper ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #fff;
    padding: 2rem 3rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .hs-menu-wrapper.active ul {
    display: flex;
    background-color: #fff;
    z-index: 20;
  }

  .menu-toggle {
    display: block;
  }

  .checkout_container {
    top: 0;
  }
  .cart_products > div.cart_products_list {
    position: sticky;
    top: 100px;
  }
  .info-form .logo {
    width: 100% !important;
  }
  .producto-info p {
    font-size: 12px;
  }
  .producto-info > div {
    margin-right: 1rem;
  }
  form {
    margin: 2rem auto;
  }

  .account_container {
    flex-direction: column;
  }
  .mis-compras .user {
    width: calc(100% - 4rem);
  }
  .envio-detalles .detalleEnvio {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .envio-detalles .detalleEnvio > div span {
    margin-inline: .5rem 0;
  }
  .resumen-carrito p:not(.cart_total) {
    margin-block: 0 .5rem;
  }
  p.cart_total {
    margin-block-end: 0;
  }
  fieldset.detalleEnvio div {
    width: 100%;
  }
}

@media (max-width: 476px) {
  form fieldset>div {
    width: 100%;
  }
  form fieldset {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .galeria {
    grid-template-columns: auto;
  }
  .galeria .item_product {
    width: calc(100% - 2rem - 2px);
  }
  .mis-compras .lista-compras form {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .mis-compras .lista-compras form input {
    min-width: 100%;
  }
  button.btn.verde.send {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .cont-header {
    justify-content: center;
  }
  .header_container {
    flex-wrap: wrap;
    justify-content: center;
  }
  nav.header-iconos {
    margin-inline: auto 1rem;
  }
  .menu-toggle {
    margin-inline: 0 auto;
    margin-block: 0;
  }
  .header_container .hs-menu-wrapper ul {
    top: calc(116px + 0rem);
  }
  .additional-buttons {
    flex-wrap: wrap;
  }
  .cont-carrito .checkout_container {
    min-width: auto;
  }
  .cart_products>div.cart_products_list {
    top: 130px;
  }
  .producto-carrito {
    flex-wrap: wrap;
  }
  .cant-buttons {
    width: 100%;
    justify-content: space-between;
  }
  button.btn-clear {
    margin-left: auto;
  }
}
/********************************************/

iframe#placetopay_ {
  border-radius: .25rem;
}


/********************************************/

a.whatsapp {
  display: grid;
  place-content: center;
  padding: .5rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}
a.whatsapp svg {
  fill: var(--verde);
}




/******************************/

.detalle-producto {
  padding: 4rem;
}
.productos-detalles {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  margin: 2rem 0;
  justify-content: space-between;
  align-items: stretch;
}

.producto {
  border: 1px solid;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 46%;
}







.item_product.placeholder {
  opacity: 0.7;
  animation: pulse 1.5s infinite;
}

/* Placeholder animado */
.animate {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Placeholder de imagen */
.image-placeholder {
  height: 200px;
  background-color: #e0e0e0;
  border-radius: 8px;
  width: 100%;
}

/* Placeholder para tipo y nombre */
.tipo-placeholder,
.nombre-placeholder {
  height: 20px;
  width: 150px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

/* Placeholder para precio y stock */
.precio-placeholder {
  height: 20px;
  width: 80px;
  background-color: #e0e0e0;
  margin-right: 10px;
}

.stock-placeholder {
  height: 20px;
  width: 30px;
  background-color: #e0e0e0;
}

/* Placeholder para el botón */
.buy-now-placeholder {
  width: 100px;
  height: 35px;
  border-radius: 8px;
  background-color: #e0e0e0;
  margin-top: 20px;
}

/* Añadir una animación de "pulso" */
@keyframes pulse {
  0% {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  100% {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}


h4.tipo[value=VENTA], h4.tipo[value=EQUIPAMIENTO], h4.tipo[value=EMPAQUETAMIENTO] {
  display: none;
}